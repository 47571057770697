import {
  mdiAccountCheck,
  mdiCheckCircle,
  mdiClockTimeSevenOutline,
  mdiCodeBraces,
  mdiCogs,
  mdiGithub,
  mdiKey,
  mdiKeyVariant,
  mdiMicrosoftAzureDevops,
  mdiPlusMinus,
  mdiRocketLaunch,
  mdiSourcePull,
  mdiSourceRepository,
  mdiTag,
  mdiTagMultiple,
  mdiTestTube,
  mdiTestTubeEmpty,
  mdiTicketOutline,
  mdiToggleSwitch,
  mdiTrashCan,
} from '@mdi/js'
import { createVuetify } from '@simplifi/vuetify'

const icons = {
  aliases: {

    // Environments
    dev: mdiCodeBraces,
    test: mdiTestTubeEmpty,
    beta: mdiTestTube,
    prod: mdiCheckCircle,

    // Entities
    configurationKey: mdiCogs,
    deployment: mdiRocketLaunch,
    diff: mdiPlusMinus,
    feature: mdiToggleSwitch,
    key: mdiKey,
    pullRequest: mdiSourcePull,
    repository: mdiSourceRepository,
    reviewer: mdiAccountCheck,
    secret: mdiKeyVariant,
    tag: mdiTag,
    tags: mdiTagMultiple,
    ticket: mdiTicketOutline,

    // Statuses
    age: mdiClockTimeSevenOutline,
    unused: mdiTrashCan,

    // Brands
    azureDevops: mdiMicrosoftAzureDevops,
    github: mdiGithub,
  },
}

export default createVuetify({
  icons,
})
